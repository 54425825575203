<template>
  <div id="app">
    <el-row type="flex" justify="space-around">
      <el-col :xs="24" :lg="10">
        <el-card shadow="always" id="inputContent">
          <el-form :model="userInfo" ref="dynamicValidateForm" :rules="rules">
            <el-form-item label="用户名" prop="usrName">
              <el-input v-model="userInfo.usrName"></el-input>
            </el-form-item>
            <el-form-item prop="usrEmail" label="邮箱" v-if="this.$store.state.systemConfig.common.sentCodeMode == 0">
              <el-input v-model="userInfo.usrEmail">
                <el-button slot="append" @click="sendEmailCode">{{ this.timerTime }}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="usrPhone" label="手机号码" v-else>
              <el-input v-model="userInfo.usrPhone">
                <el-button slot="append" @click="sendEmailCode">{{ this.timerTime }}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="emailCode" label="验证码">
              <el-input v-model="userInfo.emailCode">

              </el-input>
            </el-form-item>
            <el-form-item label="密码" prop="usrPwd">
              <el-input type="password" v-model="userInfo.usrPwd" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="userInfo.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="usrEmail" label="邮箱" v-if="this.$store.state.systemConfig.common.sentCodeMode == 1">
              <el-input v-model="userInfo.usrEmail"></el-input>
            </el-form-item>
            <el-form-item prop="usrPhone" label="手机号码" v-else>
              <el-input v-model="userInfo.usrPhone"></el-input>
            </el-form-item>
            <el-form-item prop="usrQQ" label="QQ">
              <el-input v-model.number="userInfo.usrQQ"></el-input>
            </el-form-item>
            <el-form-item label="微信">
              <el-input v-model.number="userInfo.usrWechat"></el-input>
            </el-form-item>
            <el-row style="margin-top: 10px">
              <el-col :span="12" :offset="6">
                <el-button type="success" @click="register('dynamicValidateForm')" round style="width: 100%">
                  {{ $t('login.register') }}
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-button @click="resetForm('dynamicValidateForm')">{{ $t('login.reset') }}</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import users from "@/request/users";

export default {
  name: "register",
  data() {
    const validPassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('只能输入字母与数字.'))
      } else {
        callback()
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.userInfo.checkPass !== '') {
          this.$refs.dynamicValidateForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userInfo.usrPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (!Number.isFinite(value)) {
        callback(new Error('QQ必须为数字值!'));
      } else {
        callback();
      }
    };
    return {
      ask: new users(),
      lang: 'English',
      timer: null,
      timerTime: '发送',
      userInfo: {
        usrName: '',
        usrPwd: '',
        checkPass: '',
        usrEmail: '',
        usrQQ: '',
        usrWechat: '',
        usrPhone: '',
        emailCode: ''
      },
      rules: {
        usrName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur'},
          {validator: validPassword, trigger: 'blur'}
        ],
        emailCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        usrPwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 5, max: 8, message: '长度在 5 到 8 个字符', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        usrEmail: [
          {required: this.$store.state.systemConfig.common.sentCodeMode == 0, message: '请输入邮箱地址', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        usrQQ: [
          {validator: validateQQ, trigger: 'blur'}
        ],
        usrPhone: [
          {required: this.$store.state.systemConfig.common.sentCodeMode == 1, message: '请输入手机号码', trigger: 'blur'},
          {min: 11, max: 11, message: '长度在11个字符', trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ask.regUser(this.userInfo).then(res => {
            this.$message({
              showClose: true,
              duration: 0,
              message: '注册成功',
              type: 'success'
            });
            this.$router.go(-1)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * 发送邮箱验证码
     */
    sendEmailCode() {
      const isEmail = this.$store.state.systemConfig.common.sentCodeMode == 0;
      this.$refs.dynamicValidateForm.validateField(isEmail ? 'usrEmail' : 'usrPhone', resule => {
        if (!resule) {
          if (this.timer == null) {
            this.timerTime = 60;
            this.timer = setInterval(() => {
              this.timerTime--;
              if (this.timerTime <= 0) {
                clearInterval(this.timer);
                this.timerTime = "发送";
                this.timer = null;
              }
            }, 1000)
            this.ask.sendEmailCode({'usrEmail': isEmail ? this.userInfo.usrEmail : this.userInfo.usrPhone});
          }
        }
      })
    }
  },
  watch: {
    '$store.state.systemConfig.common.sentCodeMode': function (o, n) {
      this.rules.usrEmail = [
        {required: this.$store.state.systemConfig.common.sentCodeMode == 0, message: '请输入邮箱地址', trigger: 'blur'},
        {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
      ]
      this.rules.usrPhone = [
        {required: this.$store.state.systemConfig.common.sentCodeMode == 1, message: '请输入手机号码', trigger: 'blur'},
        {min: 11, max: 11, message: '长度在11个字符', trigger: 'blur'}
      ]
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>

</style>
